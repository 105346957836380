<template>
  <div id="divInvoicesWrapper">
    <div class="content-row border-bottom">
      <div class="content-layout-fixer">
        <div class="top-content-wrapper">
          <div>
            <div class="top-content-actions">
              <BrandInvoiceToolBar :routes="routes" :active-route="routes.bills" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="people-wrapper">
          <VueGridContainer>
            <VueGridRow>
              <VueGridCol>
                <date-pick
                  :weekdays="weekdays"
                  nextMonthCaption="Sonraki Ay"
                  prevMonthCaption="Önceki Ay"
                  class="date-picker-container"
                  v-model="startDate"
                  :displayFormat="'DD.MM.YYYY'"
                  :format="'YYYY-M-D'"
                  :months="months"
                  :isDateDisabled="isDateDisabled"
                >
                  <template v-slot:default="{ toggle, inputValue }">
                    <div class="date-picker-wrapper">
                      <div class="picker-content">
                        <VueText sizeLevel="2" weightLevel="1" color="grey-30"
                          >Başlangıç Tarihi</VueText
                        >
                        <VueText sizeLevel="4" weightLevel="3" color="grey-40">{{
                          inputValue
                        }}</VueText>
                      </div>
                      <div class="picker-toggle" @click="toggle">
                        <VueIcon
                          :iconName="icon.chevronDown.name"
                          :width="icon.chevronDown.width"
                          :height="icon.chevronDown.height"
                        ></VueIcon>
                      </div>
                    </div>
                  </template>
                </date-pick>
              </VueGridCol>
              <VueGridCol>
                <date-pick
                  :weekdays="weekdays"
                  nextMonthCaption="Sonraki Ay"
                  prevMonthCaption="Önceki Ay"
                  class="date-picker-container"
                  v-model="endDate"
                  :displayFormat="'DD.MM.YYYY'"
                  :format="'YYYY-M-D'"
                  :months="months"
                  :isDateDisabled="isDateDisabled"
                >
                  <template v-slot:default="{ toggle, inputValue }">
                    <div class="date-picker-wrapper">
                      <div class="picker-content">
                        <VueText sizeLevel="2" weightLevel="1" color="grey-30"
                          >Bitiş Tarihi</VueText
                        >
                        <VueText sizeLevel="4" weightLevel="3" color="grey-40">{{
                          inputValue
                        }}</VueText>
                      </div>
                      <div class="picker-toggle" @click="toggle">
                        <VueIcon
                          :iconName="icon.chevronDown.name"
                          :width="icon.chevronDown.width"
                          :height="icon.chevronDown.height"
                        ></VueIcon>
                      </div>
                    </div>
                  </template>
                </date-pick>
              </VueGridCol>
              <VueGridCol>
                <a class="apply-btn" @click="getInvoices()">Uygula</a>
              </VueGridCol>
            </VueGridRow>
          </VueGridContainer>
          <div v-if="dateValidation.validate && !dateValidation.valid" class="date-validation">
            {{ dateValidation.message }}
          </div>
        </div>
        <a class="download-file" @click="getInvoicesExport()">
          <span>
            <VueIcon
              :iconName="icon.share.name"
              :width="icon.share.width"
              :height="icon.share.height"
            ></VueIcon>
          </span>
          <span class="save-text">
            <VueText sizeLevel="4" color="grey-30" class="text">Kaydet</VueText>
          </span>
        </a>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <table>
          <tr class="table-header-row">
            <th>
              <VueText sizeLevel="4" weightLevel="2" color="grey-30">Fatura Tarihi</VueText>
            </th>
            <th>
              <VueText sizeLevel="4" weightLevel="2" color="grey-30">Fatura No</VueText>
            </th>
            <th>
              <VueText sizeLevel="4" weightLevel="2" color="grey-30">Fatura Tutarı</VueText>
            </th>
          </tr>
          <tr v-if="!invoices || invoices.length == 0" class="nodata-warning">
            <td colspan="3">Seçtiğiniz tarih aralığında fatura bulunmuyor.</td>
          </tr>
          <tr v-for="invoice in invoices" :key="invoice.invoiceNumber">
            <td class="table-data">
              <VueText sizeLevel="3" weightLevel="2" color="grey-30">{{
                invoice.invoiceDate
              }}</VueText>
            </td>
            <td class="table-data">
              <VueText sizeLevel="3" weightLevel="2" color="grey-20">{{
                invoice.invoiceNumber
              }}</VueText>
            </td>
            <td id="invoice-amount" class="table-data">
              <VueText sizeLevel="3" weightLevel="4" color="grey-50"
                >{{ invoice.invoiceAmount | currencyFormat }} TL</VueText
              >
            </td>
          </tr>
        </table>

        <div class="totalAmount">
          <table v-if="invoices.length > 0">
            <tr>
              <td>Toplam Fatura Adeti:</td>
              <td>{{ invoices.length }}</td>
            </tr>
            <tr>
              <td>Toplam Fatura Tutarı:</td>
              <td>{{ invoices.reduce((a, b) => a + b.invoiceAmount, 0) | currencyFormat }} TL</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandInvoiceToolBar from '@/components/brand/BrandInvoiceToolBar/BrandInvoiceToolBar.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueGridContainer from '@/components/shared/VueGridContainer/VueGridContainer.vue';
import VueGridRow from '@/components/shared/VueGridRow/VueGridRow.vue';
import VueGridCol from '@/components/shared/VueGridCol/VueGridCol.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { Finance } from '@/services/Api/index';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi.js';
import DownloadFile from '@/utils/downloadFile.js';
import currencyFilter from '@/mixins/currencyFilter.js';
import dateUtils from '@/mixins/dateUtils';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/src/vueDatePick.scss';

export default {
  name: 'Bills',
  components: {
    VueGridContainer,
    VueGridRow,
    VueGridCol,
    VueText,
    BrandInvoiceToolBar,
    VueIcon,
    DatePick,
  },
  mixins: [currencyFilter, dateUtils],
  data() {
    let currentDate = new Date();
    let start = `${
      currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear()
    }-${currentDate.getMonth() === 0 ? 12 : currentDate.getMonth()}-${currentDate.getDate()}`;
    let end = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

    return {
      weekdays: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
      months: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      startDate: start,
      endDate: end,
      excelFile: null,
      dateValidation: {
        valid: true,
        validate: false,
        message: 'Tarih aralığı 1 aydan fazla olmamalı',
      },
      selectedInvoiceNumber: '',
      selectedInvoiceByteArray: '',
      renderKey: 1,
      invoices: [],
      isCurrentTab: true,
    };
  },
  computed: {
    icon() {
      return {
        share: ICON_VARIABLES.share,
        chevronDown: ICON_VARIABLES.chevronDown,
      };
    },
    routes() {
      return {
        bills: `${RoutesPmi.Bills.path}`,
        eArchive: `${RoutesPmi.Archive.path}`,
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    icons() {
      return COMPONENT_CONSTANTS.ICON_VARIABLES;
    },
  },
  mounted() {
    this.getInvoices();
  },
  watch: {
    startDate: {
      handler: function() {
        this.validateDate();
      },
    },
    endDate: {
      handler: function() {
        this.validateDate();
      },
    },
  },
  methods: {
    isDateDisabled(date) {
      const currentDate = new Date();
      return date > currentDate || date.getFullYear() < 2007;
    },
    validateDate() {
      this.$set(this.dateValidation, 'validate', true);
      let arrStart = this.startDate.split('-');
      let arrEnd = this.endDate.split('-');

      let dayStart = new Date(arrStart[0], arrStart[1] - 1, arrStart[2]);
      let dayEnd = new Date(arrEnd[0], arrEnd[1] - 1, arrEnd[2]);

      // To calculate the time difference of two dates
      var Difference_In_Time = dayEnd.getTime() - dayStart.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (Difference_In_Days > 31) {
        this.$set(this.dateValidation, 'valid', false);
        this.$set(this.dateValidation, 'message', 'Tarih aralığı bir aydan fazla olmamalı');
      } else if (dayEnd < dayStart) {
        this.$set(this.dateValidation, 'valid', false);
        this.$set(this.dateValidation, 'message', 'Bitiş tarihi başlangıçtan büyük olmalı');
      } else {
        this.$set(this.dateValidation, 'valid', true);
        this.$set(this.dateValidation, 'message', '');
      }
      return this.dateValidation.valid;
    },
    getInvoices() {
      if (this.validateDate()) {
        this.$set(this.dateValidation, 'validate', false);

        Finance.getInvoices(this.startDate, this.endDate).then(res => {
          const {
            Data: { invoces },
          } = res.data;

          this.invoices = invoces;
        });
      }
    },
    getInvoicesExport() {
      Finance.getArchiveExport(this.startDate, this.endDate).then(res => {
        if (res.data.Data) {
          this.excelFile = res.data.Data.excelFile;

          new DownloadFile().download(
            this.excelFile.fileDownloadName,
            `data:application/vnd.ms-excel;base64,${this.excelFile.fileContents}`,
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-row {
  height: unset;
  padding-left: 20px;
  position: relative;
}

.gray-bg {
  background: #f8f9f9;
  margin-bottom: 60px;
  padding: 17px 0;
}

/deep/ .crumbs-holder {
  padding-left: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}

.top-content-wrapper {
  padding: 0;
}

.people-wrapper {
  max-width: 600px;
  padding-left: palette-space-level(10);

  .date-validation {
    color: palette-color-level('red', 30);
    padding-left: 2px;
    padding-top: 5px;
    font-size: 13px;
  }

  .date-picker-container {
    max-width: 176px;
    width: 100%;

    .date-picker-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: palette-space-level(15) palette-space-level(20);
      border-radius: 5px;
      background-color: #ffffff;
      border: 1px solid palette-color-level('grey', 20);

      .picker-content {
        p {
          &:first-child {
            padding-bottom: palette-space-level(5);
          }
        }
      }

      .picker-toggle {
        cursor: pointer;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.4);
        }
      }
    }
  }
}

.person {
  margin-right: 11px;
  margin-left: 10px;
}

.top-content-actions {
  display: flex;
  align-items: space-between;
  z-index: 5;
  margin-top: 10px;

  button:not(:first-child) {
    margin-left: palette-space-level(10);
  }

  button:not(:last-child) {
    margin-right: palette-space-level(10);
  }
}

table {
  border: 1px solid palette-color-level('grey', '20');
  margin-bottom: palette-space-level('30');
  width: 100%;

  .nodata-warning {
    td {
      background-color: #f8f9f9;
      color: #e69300;
    }
  }

  tr,
  td,
  th {
    padding: palette-space-level(15);
    text-align: center;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid palette-color-level('grey', '20');
  }

  td:not(:last-child),
  th:not(:last-child) {
    border-right: 1px solid palette-color-level('grey', '20');
  }

  td#invoice-amount,
  th:nth-child(3) {
    text-align: center;
  }
}

.table-header-row {
  background-color: palette-color-level('grey', '10');
  padding: palette-space-level(25) 0;
}

.totalAmount {
  display: block;
  float: right;
  min-width: 313px;
  font-size: 14px;
  color: #79838e;

  table {
    background-color: #f8f9f9;
    border-left: none;
    border-right: none;
  }

  table > tr > td {
    text-align: left;
    border: none;

    &:first-child {
      width: 163px;
    }

    &:last-child {
      padding-left: 0;
    }
  }
}

.hidden-date {
  width: 100%;
  min-width: 97%;
  height: 60px;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.hidden-date[type='date']::-webkit-calendar-picker-indicator {
  display: block;
  width: 100%;
  height: 58px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.border-bottom {
  border-bottom: solid 1px #b4c2d3;
  margin-bottom: 40px;
}

.download-file {
  display: block;
  position: absolute;
  right: 30px;
  top: 50%;
  cursor: pointer;
  margin-top: -9px;

  .save-text {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    top: -3px;
  }
}

.apply-btn {
  display: inline-block;
  margin-left: 15px;
  width: 180px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: palette-radius-level('15');
  background-image: linear-gradient(to left, #d20051, #ffa300);
  margin-right: 15px;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}</style
><style>
.vdpArrowPrev:after {
  border-right-color: #e47b49;
}

.vdpArrowNext:after {
  border-left-color: #e47b49;
}

.vdpCellContent {
  border-radius: 15%;
}

.vdpCell.selectable:hover .vdpCellContent {
  background: linear-gradient(315deg, #d20051, #ffa300);
  opacity: 0.8;
}

.vdpCell.selected .vdpCellContent {
  background: linear-gradient(315deg, #d20051, #ffa300);
}

.vdpCell.today {
  color: #e47b49;
}
</style>
